<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import ListMurid from "@/router/views/murid/listMurid";

/**
 * Starter component
 */
export default {
  page: {
    title: "Data Murid Pengguna Aplikasi",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader,ListMurid },
  data() {
    return {
      title: "Data Murid Pengguna Aplikasi",
      items: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "Data Murid",
          active: true
        }
      ],
    };
  },
  computed: {
    // a computed getter
    state: function () {
      return this.$store.getters["murid/states"];
    },
    notif: function () {
      return this.$store.getters["murid/notif"];
    }
  }
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
        <b-alert show dismissible fade v-show="notif != null">{{notif}}</b-alert>
        <div class="col-md-12"  v-show="state == 'LIST'">
            <ListMurid />
        </div>
    </div>
  </Layout>
</template>