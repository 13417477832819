<script>

/**
 * Contacts-list component
 */
import Swal from "sweetalert2";
export default {
  data() {
    return {
        id_sekolah : "",
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: true,
        fields: [
            { key: "picture",label:"Picture", sortable: false },
            { key: "name",label:"Nama", sortable: true },
            { key: "email",label:"Email", sortable: true },
            { key: "phone",label:"No Telp", sortable: false },
            { key: "sekolah",label:"Sekolah", sortable: true },
            { key: "Actions", sortable: false }
        ]
    };
  },
  computed: {
    // a computed getter
    murids: function () {
      return this.$store.getters["murid/items"];
    },
    rows() {
        return this.murids.length;
    },
    loading: function () {
      return this.$store.getters["murid/loading"];
    },
  },
  mounted(){
    this.$store.dispatch("murid/getMurids",{offset:0,limit:100});
  },
  methods : {
    firstChar(str){
        console.log(str)
        return str.charAt(0).toUpperCase();
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
    },
    confirm(id) {
        console.log(id)
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Yang Di hapus Tidak dapat di kembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Ya, Hapus !"
      }).then(result => {
        if (result.value) {
        //   this.$store.dispatch("guru/hapusGuru",id);
          Swal.fire("Deleted!", "Berhasil Data Akan Segera Terhapus.", "success");
        //   this.$store.dispatch("guru/getGuru");
        }else{
          Swal.fire("Cancel!", "Data Tidak Terhapus", "error");
        //   this.$store.dispatch("guru/getGuru");
        }
      });
    },
  }
};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <div class="row mt-4">
                <div class="col-sm-8 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                            Show&nbsp;
                            <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                        </label>
                    </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                        <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                            v-model="filter"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ml-2"
                            ></b-form-input>
                        </label>
                    </div>
                </div>
                <!-- End search -->
            </div>
                <!-- Table -->
            <div class="table-responsive mb-0">
                <b-table
                    :items="murids"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                >
                    <template #cell(name)="row">
                      <h5 class="font-size-14 mb-1">
                        <a href="#" class="text-dark">{{row.item.name}}</a>
                      </h5>
                    </template>
                    <template #cell(sekolah)="row">
                        <p>{{row.item.sekolah.nama}}</p>
                    </template>
                    <template #cell(picture)="row">
                        <div class="avatar-xs" v-if="row.value.picture == null">
                            <span class="avatar-title rounded-circle">{{firstChar(row.item.name)}}</span>
                        </div>
                        <div v-else>
                            <img class="rounded-circle avatar-xs" :src="`http://103.41.207.247:3000/${row.item.picture}`" alt />
                        </div>
                    </template>
                    <template #cell(Actions)="row">
                        <button class="btn btn-danger btn-sm  mx-1"  v-b-tooltip.hover title="Delete" @click="confirm(row)"><i class="bx bx-trash"></i></button>
                    </template>
                </b-table>
            </div>
            <div class="row">
                <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>